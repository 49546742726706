import React from 'react';
import { graphql, Link } from 'gatsby';
import { DashboardLayout } from '../modules/layout/components/dashboard-layout';
import { IBCMSkillPage } from '../modules/common/model/graphql-types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

import './employee.scss';
import './bcm-character-dynamic.scss';
import { SectionHeader } from '../modules/common/components/section-header';
import { Alert, Col, Row } from 'react-bootstrap';
import { Seo } from '../modules/common/components/seo';
import { Options } from '@contentful/rich-text-react-renderer';
import { INLINES, BLOCKS } from '@contentful/rich-text-types';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCube, faHistory } from '@fortawesome/free-solid-svg-icons';
import { BCMRarity } from '../modules/bcm/common/components/bcm-rarity';
import { BCMSeasonal } from '../modules/bcm/common/components/bcm-seasonal';
import { BCMClass } from '../modules/bcm/common/components/bcm-class';
import { BCMStatus } from '../modules/bcm/common/components/bcm-status';
import { BCMSkillPage } from '../modules/bcm/common/components/bcm-skillpage';
import { BCMCharacter } from '../modules/bcm/common/components/bcm-character';

const Text = ({ children }) => <p>{children}</p>;

const options: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      if (node.data.uri.startsWith('/')) {
        return <Link to={node.data.uri}>{children}</Link>;
      } else {
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      }
    },
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    ['BLOCKS.EMBEDDED_ASSET']: (node) => {
      return (
        <>
          <pre>
            <code>{JSON.stringify(node, null, 2)}</code>
          </pre>
        </>
      );
    },
    'embedded-asset-block': (node) => {
      const { localFile } = node.data.target;
      if (!localFile || !localFile.childImageSharp) {
        return null;
      }
      const image = getImage(localFile.childImageSharp);
      return <GatsbyImage image={image} alt="" />;
    }
  }
};

interface IBCMSkillPageNodes {
  nodes: IBCMSkillPage[];
}

interface IBCMSkillPageEntry {
  currentUnit: IBCMSkillPageNodes;
}

interface IProps {
  data: IBCMSkillPageEntry;
}

const BCMSkillPageDetails: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <DashboardLayout
      className={'generic-page character-page character-bcm'}
      game="bcm"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/black-clover/">Black Clover M</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/black-clover/skill-pages">Skill pages</Link>
        </li>
        <li className="divider">/</li>
        <li>{character.name}</li>
      </ul>
      <div className="character-header ag">
        <BCMSkillPage mode="icon" slug={character.slug} />
        <div className="character-details">
          <h1>
            {character.name}{' '}
            {character.isNew && <span className="tag new">NEW!</span>}{' '}
          </h1>
          <div className="character-roles-and-types">
            <BCMRarity rarity={character.rarity} />
            <BCMClass classChar={character.class} />
            {character.isSeasonal && <BCMSeasonal season={character.season} />}
          </div>
        </div>
      </div>
      <div className="id-section">
        <SectionHeader title="Profile" />
        <Row xs={1} xl={2} xxl={3} className="info-list">
          <Col>
            <h5>Page information</h5>
            <div className="info-list-row">
              <div className="category">Name</div>
              <div className="details">{character.name}</div>
            </div>
          </Col>
          <Col>
            <h5>Combat information</h5>
            <div className="info-list-row">
              <div className="category">Rarity</div>
              <div className="details">{character.rarity}</div>
            </div>
            <div className="info-list-row">
              <div className="category">Class restriction</div>
              <div className="details">{character.class}</div>
            </div>
          </Col>
        </Row>
        <div className="fuse-ad-placeholder" data-fuse="22844297232"></div>
      </div>
      <div className="id-section">
        <SectionHeader title="Passive" />
        <Row className="skills">
          <Col xs={12} xxl={12}>
            <div className="skill-box bcm">
              <div className="skill-content">
                <h5>{character.name}</h5>
                <>{renderRichText(character.passive, options)}</>
                {character.upgrades && (
                  <>
                    <hr />
                    <div className="buff-row">
                      {character.upgrades.map((status, index3) => {
                        return (
                          <BCMStatus
                            status={status.name}
                            level={status.level}
                            value={status.value}
                            key={index3}
                          />
                        );
                      })}
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {character.character && (
        <div className="id-section">
          <SectionHeader title="Skill upgrade" />
          <Alert variant="primary" className="alert-char">
            <p>
              This skill will upgrade one of this character skills (it won't do
              anything for other characters):
            </p>
            <BCMCharacter
              mode="icon"
              slug={character.character.slug}
              enablePopover
            />
          </Alert>
          {character.character.skills ? (
            <>
              <Row className="skills">
                {character.character.skills.map((skill, index) => {
                  return (
                    <>
                      {skill.hasEnhancedVersion && (
                        <>
                          <Col xs={12} xxl={6} key={index}>
                            <div className="skill-box bcm">
                              <div className="skill-top before">
                                <p>Before</p>
                              </div>
                              <div className="skill-header">
                                <div className={`skill-icon Sense`}>
                                  <FontAwesomeIcon width="36" icon={faCube} />
                                </div>
                                <div className="name-section">
                                  <h5 className="name">{skill.name}</h5>
                                  <div className="pills">
                                    <span className="skill-type pill">
                                      {skill.type}
                                    </span>
                                    {skill.target && (
                                      <span className="skill-type pill">
                                        {skill.target}
                                      </span>
                                    )}
                                    {skill.cooldown && (
                                      <span className="pill cooldown">
                                        <FontAwesomeIcon
                                          icon={faHistory}
                                          width="18"
                                        />
                                        {skill.cooldown} turns
                                      </span>
                                    )}
                                    {skill.atk && (
                                      <span className="skill-atk pill">
                                        ATK: {skill.atk}%
                                      </span>
                                    )}
                                    {skill.matk && (
                                      <span className="skill-matk pill">
                                        MATK: {skill.matk}%
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="skill-content">
                                {skill.description && (
                                  <>
                                    {renderRichText(skill.description, options)}
                                  </>
                                )}
                                {skill.status && (
                                  <>
                                    <hr />
                                    <div className="buff-row">
                                      {skill.status.map((status, index3) => {
                                        return (
                                          <BCMStatus
                                            status={status.name}
                                            level={status.level}
                                            value={status.value}
                                            key={index3}
                                          />
                                        );
                                      })}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} xxl={6} key={index}>
                            <div className="skill-box bcm">
                              <div className="skill-top">
                                <p>After</p>
                              </div>
                              <div className="skill-header">
                                <div className={`skill-icon Sense`}>
                                  <FontAwesomeIcon width="36" icon={faCube} />
                                </div>
                                <div className="name-section">
                                  <h5 className="name">{skill.name}</h5>
                                  <div className="pills">
                                    <span className="skill-type pill">
                                      {skill.type}
                                    </span>
                                    {skill.target && (
                                      <span className="skill-type pill">
                                        {skill.target}
                                      </span>
                                    )}
                                    {skill.cooldown && (
                                      <span className="pill cooldown">
                                        <FontAwesomeIcon
                                          icon={faHistory}
                                          width="18"
                                        />
                                        {skill.cooldown} turns
                                      </span>
                                    )}
                                    {skill.atk && (
                                      <span className="skill-atk pill">
                                        ATK: {skill.atk}%
                                      </span>
                                    )}
                                    {skill.matk && (
                                      <span className="skill-matk pill">
                                        MATK: {skill.matk}%
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="skill-content">
                                {skill.descriptionEnhanced && (
                                  <>
                                    {renderRichText(
                                      skill.descriptionEnhanced,
                                      options
                                    )}
                                  </>
                                )}
                                {skill.statusEnhanced && (
                                  <>
                                    <hr />
                                    <div className="buff-row">
                                      {skill.statusEnhanced.map(
                                        (status, index3) => {
                                          return (
                                            <BCMStatus
                                              status={status.name}
                                              level={status.level}
                                              value={status.value}
                                              key={index3}
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                        </>
                      )}
                    </>
                  );
                })}
              </Row>
            </>
          ) : (
            <Alert variant="primary">
              <p>
                <strong>
                  Skills are not available for this character yet.
                </strong>{' '}
                We will add them as soon as it is possible!
              </p>
            </Alert>
          )}
        </div>
      )}

      <div className="fuse-ad-placeholder" data-fuse="22844297001"></div>
    </DashboardLayout>
  );
};

export default BCMSkillPageDetails;

export const Head: React.FC<IProps> = ({ data }) => {
  const character = data.currentUnit.nodes[0];

  return (
    <Seo
      title={character.name + ' | Black Clover M | Prydwen Institute'}
      description={
        character.name +
        ' is one of the skill pages available in Black Clover Mobile.'
      }
      image={character.image}
    />
  );
};

export const pageQuery = graphql`
  query ($contentfulId: String) {
    currentUnit: allContentfulBlackCloverSkillPage(
      filter: { id: { eq: $contentfulId } }
    ) {
      nodes {
        ...BlackCloverSkillPageFieldsFragment
      }
    }
  }
`;
